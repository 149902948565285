import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import styles from "./Previousdrops.module.scss";
import { useFormik } from "formik";
import { SearchIcon } from "../../../Assets/svgIcons/SVGicon";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import { dropsList } from "../../../Features/drop/dropSlice";
import { useDispatch, useSelector } from "react-redux";
import { PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime, sliceChar } from "../../../Helper/common";
import { useDebounce } from "../../../customHooks/useDebounce";
import { openPopUp, saveNextRoute } from "../../../Features/user/userSlice";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import { Routes_Urls } from "../../../Constant/Route_url";
import { Helmet } from "react-helmet";

const filterOption = [
  { label: "Recent", value: 0 },
  { label: "Oldest", value: 1 },
];

const timeOptions = [
  { label: "1M", value: 1 },
  { label: "3M", value: 3 },
  { label: "6M", value: 6 },
  { label: "1Y", value: 12 },
];

const Previousdrops = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previousDrop, setPreviousDrop] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [selectedFilterOption, setSelectedFilterOption] = useState(null);
  const [selectedTimeOption, setSelectedTimeOption] = useState(12);
  const debouncedSearchTerm = useDebounce(search, 500);

  const initialValue = {
    search: "",
    filterOption: "",
  };
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: () => {},
  });

  useEffect(() => {
    drops();
  }, [page, debouncedSearchTerm, selectedTimeOption, selectedFilterOption]);

  const drops = async () => {
    try {
      let params = {
        size: PER_PAGE_RECORD_LIMIT.PREVIOUS_DROP_LIST_PER_PAGE_LIMIT,
        page: page,
        search: search,
        duration: selectedTimeOption,
        filter: selectedFilterOption?.value,
      };
      if (
        selectedFilterOption?.value === undefined ||
        selectedFilterOption === null
      ) {
        delete params.filter;
      }

      const response = await dispatch(dropsList(params));
      setPreviousDrop(response.data.data.previous_drops.rows);
      setTotalPages(response.data.data.previous_drops.totalPages);
    } catch (err) {}
  };

  const checkNavigation = (e, item) => {
    navigate(`/profile/${item?.artist_name}`);
  };

  return (
    <>
      <section className={styles.previousDrops}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://www.newartx.com/previous-drops" />
        </Helmet>
        <Container>
          {/* <form onSubmit={formik.handleSubmit}> */}
          <div className={styles.top_head}>
            <h1>Previous Drop</h1>
            <div className={styles.filterlist}>
              <FormikControls
                variant={styles.searchBar}
                rightIcon={<SearchIcon />}
                control="input"
                type="text"
                name="search"
                placeholder="Search drop"
                onChange={(e) => setSearch(e.target.value)}
                formik={formik}
              />
              <ul className={styles.timeperiodlist}>
                {timeOptions.map((item) => (
                  <li
                    className={
                      selectedTimeOption === item.value && styles.active
                    }
                    onClick={() => {
                      setSelectedTimeOption(item.value);
                    }}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
              <FormikControls
                control="select"
                type="select"
                name="filter"
                placeholder="Sort By"
                formik={formik}
                className={styles.filtersel}
                value={selectedFilterOption}
                onChange={setSelectedFilterOption}
                options={filterOption}
              />
            </div>
          </div>
          <div className={styles.statsTable}>
            <CommonTable className={styles.drop_table}>
              <thead>
                <tr>
                  <th>
                    <span>Date</span>
                  </th>
                  <th>
                    <span>Drop Name</span>
                  </th>
                  <th>
                    <span>Artist</span>
                  </th>
                  <th>
                    <span>Digital Arts</span>
                  </th>
                  {/* <th><span>Editions</span></th> */}
                  {/* <th><span>Floor Price</span></th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {previousDrop.map((item, index) => (
                  <tr key={index}>
                    <td>{formatDateWithTime(item.createdAt)}</td>
                    <td>
                      <div className="d-flex align-items-center thumb-outer">
                        <img
                          className="thumb"
                          src={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_image}`}
                          alt="thumb"
                        />{" "}
                        <h4 title={item.drop_name}>
                          {sliceChar(item.drop_name, 20)}
                        </h4>
                      </div>
                    </td>
                    <td
                      className="cursor-pointer"
                      title={item.artist_name}
                      onClick={(e) => checkNavigation(e, item)}
                    >
                      @{sliceChar(item.artist_name, 20)}
                    </td>
                    <td>{item.art.length}</td>
                    {/* <td>{item.edition}</td> */}
                    {/* <td>{item.collectors}</td> */}
                    {/* <td>&#8377; {item.floorprice}</td> */}
                    <td className="text-center">
                      <button
                        className={styles.notifiedBtn}
                        onClick={() => navigate(`/drop/${item.id}/previous`)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
                {previousDrop.length === 0 && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No drop found.
                    </td>
                  </tr>
                )}
              </tbody>
            </CommonTable>
          </div>
          {/* </form> */}
          {totalPages > 1 && (
            // <Pagination className="page_nation" {...paginationConfigs} />
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={totalPages}
              setActivePage={setPage}
              maxVisiblePages={5}
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default Previousdrops;

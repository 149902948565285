import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import style from "./NewsAbout.module.scss";
import NewsCard from "../../Common/Ui/NewsCard/NewsCard";
import Commondropdown from "../../Common/Ui/CommonDropdown/Commondropdown";
import { useDispatch } from "react-redux";
import { getNewsList } from "../../../Features/news/newsSlice";
import { PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import { formatDate } from "../../../Helper/common";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import Notfound from "../../Common/NotFound/Notfound";
import { Helmet } from "react-helmet";

const NewsAbout = () => {
  const dispatch = useDispatch();

  const [sortByFilter, setSortByFilter] = useState("");
  const [filterValue, setFilterValue] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [newsListData, setNewsListData] = useState([]);

  const dropvalue = [
    { href: "#", droptitle: "Newest", value: 1 },
    { href: "#", droptitle: "Oldest", value: 2 },
  ];

  useEffect(() => {
    newsListDataHandler();
  }, [page, filterValue]);

  const newsListDataHandler = async () => {
    let params = {
      page: page,
      size: PER_PAGE_RECORD_LIMIT.DIGTIAL_ART_ON_DROP_LIMIT,
      filter: filterValue,
    };
    try {
      let response = await dispatch(getNewsList(params));
      setNewsListData(response?.data?.data?.list);
      setTotalPages(response?.data?.data?.totalPages);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <>
      <section className={style.news_about}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          <link rel="canonical" href="https://www.newartx.com/news" />
        </Helmet>

        <Container>
          <div className={style.news_about_Titles}>
            <div className={style.headingtitle}>
              <h1>News About</h1>
            </div>
            <ul className={style.btnfilter}>
              <li>
                <Commondropdown
                  dropdowntitle={sortByFilter == "" ? "Sort By" : sortByFilter}
                >
                  {dropvalue.map((data) => (
                    <Dropdown.Item
                      href={data.href}
                      onClick={() => {
                        setFilterValue(data.value);
                        setSortByFilter(data.droptitle);
                      }}
                    >
                      {data.droptitle}
                    </Dropdown.Item>
                  ))}
                </Commondropdown>
              </li>
            </ul>
          </div>
          <Row>
            {newsListData?.length > 0 &&
              newsListData.map((item, index) => (
                <Col lg={4} md={6}>
                  <NewsCard
                    className={`card_withouthover`}
                    key={index}
                    imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_image}`}
                    to={item.to}
                    title={item.headline}
                    //   subtitle={}
                    onClick={() => window.open(item.url, "_blank")}
                    notified={item.notified}
                    bottomTitle={formatDate(item?.launch_date)}
                    bottomSubTitle={item.bottomSubTitle}
                  />
                  {newsListData.length === 0 && (
                    <Notfound text={"Not found."} />
                  )}
                </Col>
              ))}
          </Row>
          {totalPages > 1 && (
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={totalPages}
              setActivePage={setPage}
              maxVisiblePages={5}
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default NewsAbout;

import React, { useEffect, useState } from "react";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Carddetails from "../../Private/Userprofile/Carddetails/Carddetails";
import style from "./NotificationAll.module.scss";
import { Container, Tab, Tabs } from "react-bootstrap";
import { NotificationTable } from "./Component/NotificationTable";
import socket from "../../../Socket/socket";
import { getNotificationList } from "../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import AddressQrModal from "../../Private/Userprofile/Artowned/components/AddressQrModal";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";

export const Notification = () => {
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const [key, setKey] = useState("unread");
  const dispatch = useDispatch();
  const [notificationList, setNotificationList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [showQrAddress, setShowQrAddress] = useState(false);
  const [importData, setImportData] = useState({});
  const userDetail = useSelector((state) => state?.users?.profileData);

  // Update notification list when a new notification arrives
  socket.on("USER_NOTIFICATION", (data) => {
    // Clone the existing notification list and prepend the new notification
    let notificationListClone = [...notificationList];
    notificationListClone.unshift(data);
    // Update the state with the updated notification list
    setNotificationList(notificationListClone);
  });

  useEffect(() => {
    getNotificationHandler();
  }, [page, key, ArtistId]);

  const getNotificationHandler = async () => {
    let params = {
      page: page,
      size: 10,
      filter: key === "all" ? "ALL" : "UNREAD",
      type: 0, //ALL NOTIFICATION
    };
    try {
      const response = await dispatch(getNotificationList(params));
      setNotificationList(response.data.data);
      setTotalPage(response.data.totalPages);
    } catch (error) {}
  };

  return (
    <>
      <section className={style.notificationAll}>
        <Helmet>
          <title>NewArtX</title>
          <meta name="description" content="" />
          {/* <meta name="robots" content="noindex, nofollow" /> */}
        </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails tab={"dashboard"} />

        <Container>
          <div className={style.notificationAll_innerPage}>
            <Tabs
              activeKey={key}
              onSelect={(k) => {
                setPage(1);
                setKey(k);
              }}
              id="uncontrolled-tab-example"
              className="tablists"
            >
              <Tab eventKey="unread" title="Unread">
                <NotificationTable
                  notificationList={notificationList}
                  getNotificationHandler={getNotificationHandler}
                  page={page}
                  setPage={setPage}
                  totalPage={totalPage}
                  setImportData={setImportData}
                  setShowQrAddress={setShowQrAddress}
                />
              </Tab>
              <Tab eventKey="all" title="All">
                <NotificationTable
                  notificationList={notificationList}
                  getNotificationHandler={getNotificationHandler}
                  page={page}
                  setPage={setPage}
                  totalPage={totalPage}
                  setImportData={setImportData}
                  setShowQrAddress={setShowQrAddress}
                />
              </Tab>
            </Tabs>
          </div>
        </Container>
        {showQrAddress && Object.keys(importData).length > 0 && (
          <AddressQrModal
            show={showQrAddress}
            importData={importData}
            onHide={() => setShowQrAddress(false)}
          />
        )}
      </section>
    </>
  );
};
